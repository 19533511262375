import type { MultilinkStoryblok } from '~/types/component-types-sb';
export const getUrlFromStoryblokLink = (link: MultilinkStoryblok): string => {
    const regExp = new RegExp('/b2b|/b2c', 'ig');
    const config = useRuntimeConfig();

    // Return different links base on the linktype of Storyblok
    if (link.linktype === 'url') {
        // Should be deleted but some manual links are still from the old url structure i think
        return link.url!.replace(regExp, '');
    } else if (link.linktype === 'story' && link.story) {
        // Delete b2b because right now we work without it, because of the subdomain

        let modifiedLink = '';

        //look for all matches of the side mode from the cached url
        const matchedSiteModes = link.story.full_slug.match(regExp);

        //If there are is a match, we prepend the correspondig (sub)domain
        if (matchedSiteModes) {
            //extract the sitemode into a variable
            const urlSiteMode = matchedSiteModes[0];

            //if the siteMode from the url doesn't match, prepend the domain based on config site Mode
            if (!urlSiteMode.includes(config.public.siteMode.toLowerCase())) {
                if (config.public.siteMode == 'B2B')
                    modifiedLink += `https://${config.public.env != 'production' ? 'preview.' : ''}insta-drive.com/`;
                else
                    modifiedLink += `https://${config.public.env != 'production' ? 'firmenwagen-preview.' : 'firmenwagen.'}insta-drive.com/`;
            } else {
                //If the siteModes aligns, only append / ensure proper linking
                modifiedLink += '/';
            }
        }

        modifiedLink += link.story.full_slug.replace(regExp, '');
        // Add trailing slash
        if (!modifiedLink.endsWith('/')) {
            modifiedLink = modifiedLink + '/';
        }
        return modifiedLink;
    } else if (link.linktype === 'asset' && link.url) {
        return link.url.replace(regExp, '');
    } else if (link.linktype === 'email' && link.email) {
        return `mailto:${link.email}`;
    } else {
        console.warn('Link could not be converted to URL', link);
        return '';
    }
};
